import React from "react"
import PropTypes from "prop-types"
import Header from "../header/header"
import Footer from "../footer/footer"
import CookieConsent from "../cookie/consent"
import "./layout.scss"
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container" id="main-container">
        <main>
          <div className="layout-body ">{children}</div>
          <div className="layout-footer">
            <Footer />
          </div>
        </main>
      </div>
      <CookieConsent></CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
