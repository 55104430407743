import React from "react"
import CookieConsent from "react-cookie-consent"

const Cookie = () => {
  return (
    <CookieConsent
      buttonText="Accetto"
      style={{
        background: "white",
        borderTop: "1px solid rgb(226, 224, 224)",
        color: "grey",
        width: "100%",
        fontSize: "0.7rem",
      }}
      buttonStyle={{
        color: "green",
        fontSize: "13px",
        background: "white",
        border: "1px solid rgba(1, 136, 1, 0.644)",
        borderRadius: "5px",
        fontFamily: "'Montserrat', sans-serif;",
      }}
    >
      Fratelli Lepri Arredamenti website stores cookies on your computer which
      helps us make the website work better for you.
    </CookieConsent>
  )
}

export default Cookie
